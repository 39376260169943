/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminPharmacyAgencyList } from '../actions/admin_pharmacy_agency'
import { Container, Button, Col, Row } from 'react-bootstrap'

class AdminPharmacyAgencies extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminPharmacyAgencyList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminPharmacyAgencyList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/pharmacy_agency/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/pharmacy_agency/')
    }

    filterPharmacyAgency = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminPharmacyAgencyList.updateListFilter(filter_values))
    }

    getPharmacyAgencyCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { reseller_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>

                <FormikInputField name="any_field"
                                  placeholder="Search"
                                  show_search_icon={true} />
              </div>
            </Col>
        )
    }

    render() {
        const {
            pharmacy_agency_headers,
            pharmacy_agencies,
            is_loading,
            pharmacy_agency_filter,
        } = this.props
        
        return (
            <AdminMainLayout active_key="pharmacy_agencies"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'pharmacy_agencies', label: 'Pharmacy Agencies', url: '/admin/pharmacy_agencies'}]}>
              <AdminTableHeader title="Pharmacy Agencies">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter renderFilter={this.renderFilter}
                                    updateOnChange={ this.filterPharmacyAgency }
                  />
                </div>
                <div css={ header }>
                  <WhiteButton onClick={ this.onAdd }>
                    Add Pharmacy Agency
                  </WhiteButton>
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no pharmacy agencies."
                             headers={ pharmacy_agency_headers }
                             items={ pharmacy_agencies }
                             item_list={adminPharmacyAgencyList}
                             getCellValue={ this.getPharmacyAgencyCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const pharmacy_agencies = adminPharmacyAgencyList.getVisibleObjects() || []
    return {
        pharmacy_agencies,
        pharmacy_agency_filter: adminPharmacyAgencyList.getFilter(),
        pharmacy_agency_headers: {
            name: { name: "Name" },
            action: { name: '' },
        },
        is_loading: adminPharmacyAgencyList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminPharmacyAgencies))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const reseller_filter_style = css`
width: 400px;
`

const filter_field_row_style = css`
display: flex;
`
