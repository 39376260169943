/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { jsx, css } from '@emotion/react'
import { get, head, has } from 'lodash'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import AdminTableFilter from './AdminTableFilter'
import { default_theme as theme } from '../../emotion/theme'
import content_dark from '../../images/content_dark.png'
import active_dark from '../../images/active_dark.png'
import inactive_dark from '../../images/inactive_dark.png'
import send_dark from '../../images/send_dark.png'
import sms_dark from '../../images/sms_outline_dark.png'
import notification_dark from '../../images/notification_outline_dark.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class AdminDashboard extends Component {

    render() {
        const {
            data,
            is_busy,
            is_ready,
            users,
            user_headers,
            user_filter,
            is_user_loading,
        } = this.props

        const error_colour = "#ff0000"
        const info_colour = "#63aed1"
        
        return (
            <AdminMainLayout title='Dashboard'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'dashboard', label: 'Dashboard', url: '/admin/dashboard'}]} >
              { is_busy && <BusyMask /> }
              <Container>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}
export default connect(mapStateToProps)(AdminDashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const label = css`
padding-top: 10px;
color: white;
font-weight: 600;
font-size: 18px;
`

const chart_container = css`
width: 100%;
height: 200px;
`
