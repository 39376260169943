/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const FormikInputField = ({ label, auto_scale, input_css, show_search_icon, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props)
    const className = (meta && meta.touched && meta.error && error_class) || null

    return (
        <div css={[common_style, show_search_icon ? show_search_icon_container : null]}>
          { show_search_icon && <FontAwesomeIcon icon={faSearch} css={search_icon_style} /> }
          <div css={(auto_scale && auto_scale_container) || null}>
            <Form.Control {...field} {...props} css={[className, input_css]}>
            </Form.Control>
            <FormError meta={meta} />
            <FormWarning meta={meta} />
          </div>
        </div>
    )
}

const error_class = css`
  border: 1px solid ${theme.colors.error};
`

const auto_scale_container = css`
width: 100%;
`

const common_style = css`
input {
  height: 38px;
  font-size: 16px;
}
`

const show_search_icon_container = css`
position: relative;

input {
  text-indent: 20px;
}


`

const search_icon_style = css`
position: absolute;
top: 12px;
left: 7px;
color: #b8b8bd;
`

