/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/react'
import { Component } from 'react';
import { default_theme as theme } from '../emotion/theme'

export default class GlobalStyles extends Component {

    // This class contains any styles global to all components, and that can't be over-riden using usual emotion classes.
    // 
    // If a global style is specific to a component and only used there, then put a Global in that component instead

    render() {
        return (
            <Global styles={global_styles} />
        )
    }
    
}

const global_styles = css`

body {
  background-color: ${theme.colors.page_background};
  min-height: 100vh;
  font-size: 16px;
  font-family: Lato, Helvetica Neue, Roboto;
}

body .modal-dialog {
  font-size: 16px;
  font-family: Lato, Helvetica Neue, Roboto;
}


h1 {
font-size: 28px;
margin-bottom: 0px;
white-space: nowrap;
}

ul {
list-style: disc;
margin-left: 15px;
margin-bottom: 0px;
}

li {
margin-top:10px;
}

.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #8E8E93;
}
.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #8E8E93;
   opacity:  1;
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #8E8E93;
   opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #8E8E93;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
   color:    #8E8E93;
}

.form-control::placeholder { /* Most modern browsers support this now. */
   color:    #8E8E93;
}

.nav.nav-tabs a {
  color: #000;
}

.notification-container .notification-message .message {
  background-color: transparent;
}

body button.btn-light:disabled {
  background-color: #EFEFF4;
  border-color: #EFEFF4;
}

`

