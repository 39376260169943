import { get, includes } from 'lodash'

export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL"
export const CONFIRMATION_MODAL_KEY = "CONFIRMATION_MODAL"

export function confirmModal({text, onConfirmed, can_cancel, confirm_text, cancel_text}) {
    return {
        type: SET_ACTIVE_MODAL,
        active_modal: CONFIRMATION_MODAL_KEY,
        modal_params: {text, onConfirmed, can_cancel, confirm_text, cancel_text}
    }
}

export function isProd() {
    return !includes(window.location.host, "localhost") && !includes(window.location.host, "staging")
}

export function getActiveModal(state) {
    return get(state, ['ui', 'active_modal'], null)
}

export function getModalParams(state) {
    return get(state, ['ui', 'modal_params'], {})
}

export function setActiveModal(modal_key, params) {
    return {
        type: SET_ACTIVE_MODAL,
        active_modal: modal_key,
        modal_params: params
    }
}

export function clearActiveModal() {
    return setActiveModal("")
}

