/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { MoodMomentGraph } from './MoodMomentGraph'

export const Home = ({}) => {

    return (

        <MainLayout>

          <MoodMomentGraph />
          
        </MainLayout>
        
    )
}
