/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { clearAuthentication, sendTokenEmail, refreshLoggedInUserCookieFromCustomer } from '../actions/auth'
import { Modal } from 'react-bootstrap'
import { userList } from '../actions/user'
import { customerList } from '../actions/customer'
import { Container, Row, Col } from 'react-bootstrap'
import { FormLabelValue } from './form/FormLabelValue'
import MainLayout from './MainLayout'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import FieldInfoText from './layout/FieldInfoText'
import BusyMask from './BusyMask'
import { CardHeader } from './layout/CardHeader'
import WrappingBusyMask from './WrappingBusyMask'
import { LabelValue } from './layout/LabelValue'
import Card from './layout/Card'
import { Separator } from './layout/Separator'
import { GrayButton } from './layout/GrayButton'
import { InlineIcon } from './layout/InlineIcon'
import { handleSubmitResult } from '../actions/form'
import { confirmModal } from '../actions/ui'
import {showSuccess, showError} from '../actions/Error'
import { BlueButton } from './layout/BlueButton'
import { Button } from 'react-bootstrap'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { Trans, Translation } from 'react-i18next'
import * as Yup from 'yup'

const LEFT_WIDTH = 3
const RIGHT_WIDTH = 8
const LEFT_NESTED_WIDTH = 6
const RIGHT_NESTED_WIDTH = 6

const newEmailValidationSchema = Yup.object().shape({
})

const emailOtpValidationSchema = Yup.object().shape({
})

const newPasswordValidationSchema = Yup.object().shape({
})

class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {confirming_email: null,
                      confirming_password: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
    }

    onEdit = () => {
        const { history } = this.props
        history.push("/profile/edit")
    }
    
    // onSave = (values, formik_funcs) => {
    //     const { dispatch, history, user } = this.props
    //     let on_ok = function(json) {
    //         refreshLoggedInUserCookieFromCustomer(json)
    //         showSuccess("Saved", "Account updated")
    //     }
        
    //     return dispatch(customerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    // }

    // onRequestToken = () => {
    //     const { dispatch, user } = this.props
    //     dispatch(sendTokenEmail(user))
    // }
    
    // onCancelProfile = () => {
    //     const { dispatch, history } = this.props
    //     dispatch(reset('PROFILE_FORM'))
    //     history.push('/dashboard')
    // }

    onSelectedNewEmail = (values, formik_funcs) => {
        const { dispatch, user } = this.props
        const that = this

        const on_ok = function(json) {
            that.setState({confirming_email: values.email})
        }
        return dispatch(userList.requestChangeEmail({user_id: user.id, new_email: values.email})).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onSelectedNewPassword = (values, formik_funcs) => {
        const { dispatch, user } = this.props
        const that = this

        const on_ok = function(json) {
            that.setState({confirming_password: values.password})
        }
        return dispatch(userList.requestChangePassword({user_id: user.id, new_password: values.password})).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onCancelConfirmingEmail = () => {
        this.setState({confirming_email: null})
    }

    onCancelConfirmingPassword = () => {
        this.setState({confirming_password: null})
    }

    onSelectedNewEmailOtp = (values, formik_funcs) => {
        const { dispatch, user } = this.props
        const { confirming_email } = this.state
        const that = this

        const on_ok = function(json) {
            showSuccess("Profile saved", "Email updated")
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            that.setState({confirming_email: null})
        }
        values = {user_id: user.id, new_email: confirming_email, otp: values.otp}
        return dispatch(userList.requestChangeEmailWithOtp(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onSelectedNewPasswordOtp = (values, formik_funcs) => {
        const { dispatch, user } = this.props
        const { confirming_password } = this.state
        const that = this

        const on_ok = function(json) {
            showSuccess("Profile saved", "Password updated")
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            that.setState({confirming_password: null})
        }
        values = {user_id: user.id, new_password: confirming_password, otp: values.otp}
        return dispatch(userList.requestChangePasswordWithOtp(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onCancelAccount = () => {
        const { dispatch, user } = this.props

        const onCancelledConfirmation = () => {

            showSuccess("Profile saved", "Account cancelled")
            
            const onLogout = () => {
                dispatch(clearAuthentication())
            }
            
            const text = (<Trans>Your account has been cancelled. You will be logged out now.</Trans>)
            dispatch(confirmModal({text:text,
                                   can_cancel: false,
                                   onConfirmed: onLogout}))
        }
        
        const onConfirmed = () => {
            dispatch(userList.cancelAccount({user_id: user.id})).then((res) => handleSubmitResult({res, formik_funcs: {}, on_ok: onCancelledConfirmation}))
        }
        
        const text = (<Trans>Are you sure you want to cancel your account?</Trans>)
        dispatch(confirmModal({text:text,
                               onConfirmed: onConfirmed}))
    }

    renderConfirmingEmailModal() {
        const that = this
        return (
            <Modal show={true}
                   size="lg"
                   onHide={this.onCancelConfirmingEmail} >

              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>Confirm new email</Trans>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  <Row>
                    <Col>
                      <Trans>Please check your new email inbox and enter the OTP below</Trans>:
                    </Col>
                    <Separator variant="h10" />
                  </Row>
                  <Formik
                    onSubmit={that.onSelectedNewEmailOtp}
                    initialValues={{}}
                    enableReinitialize={true}
                    validationSchema={emailOtpValidationSchema}
                  >
                    {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Translation>{ (t) => (
                                <FormikInputField
                                  name="otp"
                                  type="text"
                                  placeholder={t("One time pin")}
                                />
                            )}</Translation>
                            <Separator variant="h20" />
                            <BlueButton type="submit" auto_disable={false}>
                              <Trans>Continue</Trans>
                            </BlueButton>
                          </Form>
                      )}}
                  </Formik>
                </div>
              </Modal.Body>
            </Modal>
        )
    }

    renderConfirmingPasswordModal() {
        const that = this
        return (
            <Modal show={true}
                   size="lg"
                   onHide={this.onCancelConfirmingPassword} >

              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>Confirm new password</Trans>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  <Row>
                    <Col>
                      <Trans>Please check your email inbox and enter the OTP below</Trans>:
                    </Col>
                    <Separator variant="h10" />
                  </Row>
                  <Formik
                    onSubmit={that.onSelectedNewPasswordOtp}
                    initialValues={{}}
                    enableReinitialize={true}
                    validationSchema={emailOtpValidationSchema}
                  >
                    {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Translation>{ (t) => (
                                <FormikInputField
                                  name="otp"
                                  type="text"
                                  placeholder={t("One time pin")}
                                />
                            )}</Translation>
                            <Separator variant="h20" />
                            <BlueButton type="submit" auto_disable={false}>
                              <Trans>Continue</Trans>
                            </BlueButton>
                          </Form>
                      )}}
                  </Formik>
                </div>
              </Modal.Body>
            </Modal>
        )
    }

    renderChangeEmailForm() {
        const { confirming_email } = this.state
        return (
            <Formik
              onSubmit={this.onSelectedNewEmail}
              enableReinitialize={true}
              initialValues={{}}
              validationSchema={newEmailValidationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <div>
                        { confirming_email && this.renderConfirmingEmailModal() }
                        <Translation>{ (t) => (
                              <Form>
                                <FormikGeneralFormErrors />
                                <FormLabelValue>
                                  <Trans>Change account login</Trans>
                                  <FormikInputField
                                    name="email"
                                    type="email"
                                    placeholder={t("Enter new login email address")}
                                  />
                                </FormLabelValue>
                                <BlueButton type="submit" auto_disable={false}>
                                  <Trans>Save new login</Trans>
                                </BlueButton>
                              </Form>
                          )}</Translation>
                      </div>
                  )}}
            </Formik>
        )
    }

    renderChangePasswordForm() {
        const { confirming_password } = this.state
        return (
            <Formik
              onSubmit={this.onSelectedNewPassword}
              enableReinitialize={true}
              initialValues={{}}
              validationSchema={newPasswordValidationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <div>
                        { confirming_password && this.renderConfirmingPasswordModal() }
                        <Translation>{ (t) => (
                              <Form>
                                <FormikGeneralFormErrors />
                                <FormLabelValue>
                                  <Trans>New password</Trans>
                                  <FormikInputField
                                    name="password"
                                    type="password"
                                    placeholder={t("Enter new password")}
                                  />
                                </FormLabelValue>
                                <BlueButton type="submit" auto_disable={false}>
                                  <Trans>Change password</Trans>
                                </BlueButton>
                              </Form>
                          )}</Translation>
                      </div>
                  )}}
            </Formik>
        )
    }

    renderCancel() {
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Cancel account</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <Button variant="outline-danger" onClick={this.onCancelAccount}>
                    <Trans>Cancel account</Trans>
                  </Button>
                </Col>
              </Row>
              
            </div>
        )
    }
    
    renderLoginDetails() {
        const { user, customer } = this.props
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Account login</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  {get(customer, ["user", "email"])}
                  <Separator variant="h20" />
                  { this.renderChangeEmailForm() }
                </Col>
              </Row>
              <Separator variant="h30" />
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Change password</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  { this.renderChangePasswordForm() }
                </Col>
              </Row>
            </div>
        )
    }
    
    renderLanguage() {
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Language</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                </Col>
              </Row>
            </div>
        )
    }
    
    renderAccountDetails() {
        const { user, customer } = this.props
        
        return (
            <div>
              <Row css={edit_button_row_style}>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Name</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <LabelValue>
                    <Trans>Name</Trans>
                    {user.display_name}
                  </LabelValue>
                </Col>
                <Col md="1" offset="11" css={edit_button_style}>
                  <GrayButton onClick={this.onEdit}>
                    <div css={edit_button_content_style}>
                      <InlineIcon icon_name="edit" variant="black"/>
                      <Separator variant="w5" />
                      <Trans>Edit</Trans>
                    </div>
                  </GrayButton>
                </Col>
              </Row>
              
              <Separator variant="h10"/>
              <Row>
                <Col md={LEFT_WIDTH} css={section_name_style}>
                  <Trans>Contact details</Trans>
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <Row>
                    <Col>
                      <LabelValue>
                        <Trans>Email</Trans>
                        {customer.contact_email}
                      </LabelValue>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <LabelValue>
                        <Trans>Phone</Trans>
                        {customer.contact_phone_number}
                      </LabelValue>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
            </div>
        )
        
    }
    
    render() {
        const { user, is_loading, is_ready } = this.props
        return (
            <MainLayout active_key="profile"
                        breadcrumbs={[{name: 'home'},
                                      {name: 'account', label: 'Account', url: '/profile'}]}>
              <Container fluid>
                <CardHeader title="Account" />
                <WrappingBusyMask is_loading={!is_ready} >
                  <Row>
                    <Col md="9">
                      <Card variant="white">
                        <Col>
                          { this.renderAccountDetails() }
                        </Col>
                      </Card>
                      <Separator variant="h20" />
                      <Card variant="white" with_padding_below={false}>
                        <Col>
                          { this.renderLanguage() }
                        </Col>
                      </Card>
                      <Card variant="white" with_padding_below={false}>
                        <Col>
                          { this.renderLoginDetails() }
                        </Col>
                      </Card>
                      <Card variant="white" with_padding_below={false}>
                        <Col>
                          { this.renderCancel() }
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                </WrappingBusyMask>
              </Container>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = head(userList.getVisibleObjects()) || {}
    const customer = customerList.getCustomer() || {}
    return {
        user,
        customer,
        is_loading: customerList.isLoading() || userList.isLoading(),
        is_ready: customerList.isReady()
    }
}
export default connect(mapStateToProps)(Profile)

const  section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const address_single_line_style = css`
display: flex;
`

const edit_button_content_style = css`
display: flex;
align-items: center;
`

const edit_button_style = css`
position: absolute;
right: 40px;
`

const edit_button_row_style = css`
position: relative;
`
