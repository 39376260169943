import { ItemList } from '../orm'

class MoodMomentList extends ItemList {
    getEntityKey() {
        return "mood_moment"
    }
    
}

export const moodMomentList = new MoodMomentList("mood_moment__default")
