/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import Table from 'react-bootstrap/Table'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminCustomerList } from '../actions/admin_customer'
import { Container, Button, Col, Row } from 'react-bootstrap'

class AdminCustomers extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/customer/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/customer_profile/')
    }

    filterCustomer = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCustomerList.updateListFilter(filter_values))
    }

    sortOnHeader = (key) => {
        const { dispatch, customer_filter } = this.props
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(customer_filter, 'order_by') && customer_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminCustomerList.updateListFilter({'order_by': sort_by}))
        }
    }
    
    getCustomerCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='date' />
                )
            case 'display_name':
                return <span>{item.user.display_name}</span>
            case 'phone_number':
                return <span>{get(item, ["billing_address", "preferred_contact_telephone"], "-")}</span>
            case 'email':
                return <span>{item.user.email}</span>
            case 'credit':
                return <CurrencyValue value={item.credits_euros} />
            case 'payment_timing_method':
                return <span>?</span>
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { reseller_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>

                <FormikDropdownField name="reseller"
                                     options={reseller_options}
                                     placeholder="Reseller"
                                     extra_css={reseller_filter_style}
                                     formik_props={formik_props} />
                <Separator variant="w10" />
                <FormikInputField name="any_field"
                                  placeholder="Search"
                                  show_search_icon={true} />
              </div>
            </Col>
        )
    }

    render() {
        const {
            customer_headers,
            customers,
            is_loading,
            customer_filter,
        } = this.props
        
        return (
            <AdminMainLayout active_key="customers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'}]}>
              <AdminTableHeader title="Customers">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter renderFilter={this.renderFilter}
                                    updateOnChange={ this.filterCustomer }
                                    form="ADMIN_CUSTOMER_TABLE_FILTER_FORM"
                  />
                </div>
                <div css={ header }>
                  <WhiteButton onClick={ this.onAdd }>
                    Add Customer
                  </WhiteButton>
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no customers."
                             headers={ customer_headers }
                             items={ customers }
                             getCellValue={ this.getCustomerCellValue }
                             item_list={adminCustomerList}
                             sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customers = adminCustomerList.getVisibleObjects() || []
    return {
        customers,
        customer_filter: adminCustomerList.getFilter(),
        customer_headers: {
            short_id: { name: "Acc Id" },
            display_name: { name: "Name" },
            email: { name: 'Email' },
            phone_number: { name: 'Phone' },
            created_at: { name: 'Since' },
            credit: { name: 'Credit' },
            payment_timing_method: { name: 'Post/Pre' },
            reseller_name: { name: 'Reseller' },
            action: { name: '' },
        },
        is_loading: adminCustomerList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomers))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const reseller_filter_style = css`
width: 400px;
`

const filter_field_row_style = css`
display: flex;
`
