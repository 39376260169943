import { ItemList } from '../orm'
import { loggedInUser, refreshLoggedInUserCookieFromUser } from './auth'
import { get, size } from 'lodash'

class CustomerList extends ItemList {
    getEntityKey() {
        return "customer"
    }

    ensureCustomerLoaded() {
        return (dispatch, getState) => {
            const customer_id = get(loggedInUser(), "customer")
            if ( customer_id ) {
                return dispatch(this.ensureObjectLoaded(customer_id)).then( (res) => {
                    if ( res && size(res) == 1 ) {
                        const customer = res[0]
                        refreshLoggedInUserCookieFromUser(customer)
                    }
                })
            }
        }
    }

    getCustomer() {
        const customer_id = get(loggedInUser(), "customer")
        return this.getObject(customer_id)
    }

    invalidateCustomer() {
        const customer_id = get(loggedInUser(), "customer")
        return this.invalidateObject(customer_id)
    }
    
}

export const customerList = new CustomerList("customer__default")
