/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { adminPharmacyAgencyList } from '../actions/admin_pharmacy_agency'
import { adminProductList } from '../actions/admin_product'
import { CardHeader } from '../../components/layout/CardHeader'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required")
})

class AdminPharmacyAgency extends Component {

    componentDidMount() {
        const { dispatch, pharmacy_agency_id } = this.props
        dispatch(adminPharmacyAgencyList.ensureObjectLoaded(pharmacy_agency_id))
        dispatch(adminProductList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminProductList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, pharmacy_agency_id } = this.props
        dispatch(adminPharmacyAgencyList.ensureObjectLoaded(pharmacy_agency_id))
        dispatch(adminProductList.fetchListIfNeeded())
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, pharmacy_agency_id } = this.props

        const on_ok = function(json) {
            dispatch(adminPharmacyAgencyList.invalidateList())
            showSuccess("Saved", "Pharmacy Agency saved")

            if ( ! pharmacy_agency_id ) {
                history.push(`/admin/pharmacy_agency/${json.id}`)
            }
        }
        if ( pharmacy_agency_id ) {
            values.id = pharmacy_agency_id
            return dispatch(adminPharmacyAgencyList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminPharmacyAgencyList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderForm(formik_props) {
        const { product_options } = this.props
        return (

            <Card>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col>
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>Name</BootstrapForm.Label>
                        <FormikInputField name="name" placeholder="Name" />
                      </BootstrapForm.Group>

                    </Col>
                  </Row>
                  
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
              
            </Card>

        )
    }

    render() {
        const { initial_values, is_loading, is_busy, pharmacy_agency, pharmacy_agency_id } = this.props
        const that = this
        const title = `${get(pharmacy_agency, ["name"], 'New pharmacy agency')}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'pharmacy_agencies',
                                            label: 'Pharmacy Agencies',
                                            url: '/admin/pharmacy_agencies'},
                                           
                                           {name: 'pharmacy_agency',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(pharmacy_agency, ["name"], 'New pharmacy agency')}
                                                </div>
                                            ),
                                            url: `/admin/pharmacy_agency/${pharmacy_agency_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               { that.renderForm(formik_props) }
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const pharmacy_agency_id = get(props, ["match", "params", "pharmacy_agency_id"], null)
    const pharmacy_agency = adminPharmacyAgencyList.getObject(pharmacy_agency_id)
    return {
        pharmacy_agency_id,
        pharmacy_agency,
        product_options: adminProductList.getAsOptions(),
        is_loading: adminPharmacyAgencyList.isLoading(),
        is_busy: adminPharmacyAgencyList.getIsSavingObject(),
        initial_values: pharmacy_agency
    }
}

export default connect(mapStateToProps)(AdminPharmacyAgency)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
