/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminBrandList } from '../actions/admin_brand'
import { Container, Button, Col, Row } from 'react-bootstrap'

class AdminBrands extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminBrandList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminBrandList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/brand/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/brand/')
    }

    filterBrand = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminBrandList.updateListFilter(filter_values))
    }

    getBrandCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { reseller_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>

                <FormikInputField name="any_field"
                                  placeholder="Search"
                                  show_search_icon={true} />
              </div>
            </Col>
        )
    }

    render() {
        const {
            brand_headers,
            brands,
            is_loading,
            brand_filter,
        } = this.props
        
        return (
            <AdminMainLayout active_key="brands"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'brands', label: 'Brands', url: '/admin/brands'}]}>
              <AdminTableHeader title="Brands">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter renderFilter={this.renderFilter}
                                    updateOnChange={ this.filterBrand }
                  />
                </div>
                <div css={ header }>
                  <WhiteButton onClick={ this.onAdd }>
                    Add Brand
                  </WhiteButton>
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no brands."
                             headers={ brand_headers }
                             items={ brands }
                             getCellValue={ this.getBrandCellValue }
                             item_list={adminBrandList}
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const brands = adminBrandList.getVisibleObjects() || []
    return {
        brands,
        brand_filter: adminBrandList.getFilter(),
        brand_headers: {
            name: { name: "Name" },
            action: { name: '' },
        },
        is_loading: adminBrandList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminBrands))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const reseller_filter_style = css`
width: 400px;
`

const filter_field_row_style = css`
display: flex;
`
