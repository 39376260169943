/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import Card from './layout/Card'

export const PrivacyPolicy = ({}) => {

    return (

        <MainLayout>

          <Card variant="white">
            
            <h2>Privacy Policy</h2>

            <p>
              This platform is an experimental mood tracking system. The intention is to help users interpret what affects their mood, by algorithmically calculating a mood score and displaying daily scores for the benefit of the user.
            </p>

            <p>
              No personal data is stored. Accounts do not require an email or other identifying information, and no IP addresses are stored. Users may have multiple accounts if they choose.
            </p>

            <p>
              Textual analysis is made on text that the user is reading, however this text is not stored and the analysis is performed algorithmically and at no time is it associated with the user.
            </p>

            <p>
              The data may be used in aggregation for purposes such as calculating a median score across the user base. No identifying information is used to calculate these scores.
            </p>
            
            <p>
              This site is not to be used in association with scoring or tracking illegal content of any nature.
            </p>

            <p>
              If you would like any historical data associated with your account to be removed, please email info@impd.co.za and we will perform this task expediently.
            </p>


          </Card>
          
        </MainLayout>
        
    )
}

