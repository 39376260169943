/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Separator } from './layout/Separator'
import { withRouter } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { FormikInputField } from './form/InputField'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import InputField from './InputField'
import { moodMomentList } from '../actions/mood_moment'
import { Button } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import Card from './layout/Card'
import { size } from 'lodash'
import { login } from '../actions/auth'
import { ScatterChart, Scatter, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'

export const MoodMomentGraph = () => {

    const dispatch = useDispatch()
    const moodMoments = useSelector(() => moodMomentList.getVisibleObjects())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(moodMomentList.updatePaginationNumItemsPerPage(1000))
            dispatch(moodMomentList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const formatTimeLabel = (unixTime) => {
        return moment.unix(unixTime).format('DD MMM YYYY HH:mm')
    }
    
    const renderChart = ({title, data}) => {
        const that = this
        if ( size(data) === 0 ) {
            return null
        }
        const colour = "#63aed1"
        return (

            <Card variant="white">
              
              <div css={chart_container}>
                <h1>{title}</h1>
                <ResponsiveContainer width="100%" height="80%">
                  <ScatterChart>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey = 'captured_at_epoch'
                      domain = {['auto', 'auto']}
                      name = 'Captured at'
                      tickFormatter = {formatTimeLabel}
                      type = 'number'
                    />
                    <YAxis yAxisId="mean_score" dataKey = 'mean_score' name = 'Mean Score' domain = {[-1, 1]} />
                    <YAxis yAxisId="max_score" dataKey = 'max_score' name = 'Max Score' domain = {[-1, 1]} />
                    <YAxis yAxisId="min_score" dataKey = 'min_score' name = 'Min Score' domain = {[-1, 1]} />


                    <Scatter yAxisId="mean_score" name="Mean" data={data} line fill="gray"  />
                    <Scatter yAxisId="max_score" name="Max" data={data} line fill="green"  />
                    <Scatter yAxisId="min_score" name="Min" data={data} line fill="red"  />
                    
                  </ScatterChart>
                </ResponsiveContainer>
              </div>
            </Card>
        )
    }
    
    return (

        <div>
          {renderChart({title: 'Mood Moments', data: moodMoments })}
        </div>
        
    )

}

const chart_container = css`
width: 100%;
height: 400px;
`
