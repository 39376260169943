import { ItemList } from '../../orm'

class AdminBackgroundJobList extends ItemList {
    getEntityKey() {
        return "admin/background_job"
    }

    run(id) {
        return this.saveObject({id: id})
    }
    
}

export const adminBackgroundJobList = new AdminBackgroundJobList("admin_background_job__default")
