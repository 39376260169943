/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { get, map, size } from 'lodash'
import { withRouter } from 'react-router-dom'
import { CardHeader } from '../../components/layout/CardHeader'
import { Container, Row, Col } from 'react-bootstrap'
import { adminCustomerList } from '../actions/admin_customer'
import { SeparatorWithLine } from '../../components/layout/SeparatorWithLine'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'
import { LANGUAGES } from '../../actions/language'
import Card from '../../components/layout/Card'
import { LabelValue } from '../../components/layout/LabelValue'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { handleSubmitResult } from '../../actions/form'
import { Button } from 'react-bootstrap'
import * as Yup from 'yup'

const LEFT_PANEL__LEFT_WIDTH = 5
const LEFT_PANEL__RIGHT_WIDTH = 7
const RIGHT_PANEL__LEFT_WIDTH = 4
const RIGHT_PANEL__RIGHT_WIDTH = 8

const validationSchema = Yup.object().shape({
})

class AdminCustomerDetails extends Component {

    onEdit = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer_profile/${customer_id}`)
    }
    
    onSendPasswordResetEmail = () => {
        const { dispatch, customer_id } = this.props
        const on_ok = function(json) {
            showSuccess("Reset", "Reset email sent")
        }
        return dispatch(adminCustomerList.sendPasswordResetEmail(customer_id)).then((res) => handleSubmitResult({res, on_ok}))
    }

    onSave = (values, formik_funcs) => {
        const { dispatch, customer_id } = this.props
        const that = this

        const on_ok = function(json) {
            showSuccess("Saved", "Customer profile updated")
            dispatch(adminCustomerList.invalidateCustomer())
            dispatch(adminCustomerList.ensureCustomerLoaded())
        }
        values.id = customer_id
        return dispatch(adminCustomerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
    
    renderCustomerActions() {
        const { customer_id, customer, language_options } = this.props
        return (
            <Card variant="white_wide_padding">
              <Formik
                onSubmit={this.onSave}
                initialValues={customer}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                          <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                          
                          <Separator variant="h30" />
                          
                          <Row>
                            <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                              <Trans>Account login</Trans>
                            </Col>
                            <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                              {get(customer, ["user", "email"])}
                              <Separator variant="h20" />
                              <FormikInputField name="user.email" placeholder="Enter new login email address" />
                              <Separator variant="h20" />
                              <BlueButton type="submit">
                                Save changes
                              </BlueButton>
                            </Col>
                          </Row>

                          <Separator variant="h40" />
                          
                          <Row>
                            <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                              <Trans>Password reset</Trans>
                            </Col>
                            <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                              <Button variant="outline-danger" onClick={this.onSendPasswordResetEmail}>
                                <Trans>Send password reset email</Trans>
                              </Button>
                            </Col>
                          </Row>

                          <Separator variant="h40" />
                        </Form>
                    )
                }}
              </Formik>
            </Card>
        )
    }

    renderCustomerCard() {
        const { customer_id, customer } = this.props
        const user = customer.user

        return (
            <div>
              <Card variant="white_wide_padding">
                <Row css={edit_button_row_style}>
                  <Col md={LEFT_PANEL__LEFT_WIDTH} css={section_name_style}>
                    <Trans>Name</Trans>
                  </Col>
                  <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                    <LabelValue>
                      <Trans>Name</Trans>
                      {user.display_name}
                    </LabelValue>
                  </Col>
                  <Col md="1" offset="11" css={edit_button_style}>
                    <GrayButton onClick={this.onEdit}>
                      <div css={edit_button_content_style}>
                        <InlineIcon icon_name="edit" variant="black"/>
                        <Separator variant="w5" />
                        <Trans>Edit</Trans>
                      </div>
                    </GrayButton>
                  </Col>
                </Row>
                
                <Separator variant="h10"/>
                <Row>
                  <Col md={LEFT_PANEL__LEFT_WIDTH} css={section_name_style}>
                    <Trans>Contact details</Trans>
                  </Col>
                  <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                    <Row>
                      <Col>
                        <LabelValue>
                          <Trans>Email</Trans>
                          {customer.contact_email}
                        </LabelValue>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <LabelValue>
                          <Trans>Phone</Trans>
                          {customer.contact_phone_number}
                        </LabelValue>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
              </Card>
            </div>
        )
    }

    
    
    render() {
        const that = this
        return (
            <Row>
              <Col md={6}>
                { this.renderCustomerCard() }
              </Col>
              <Col md={6}>
                { this.renderCustomerActions() }
              </Col>                                

            </Row>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = customer_id && adminCustomerList.getObject(customer_id)
    const language_options = map(LANGUAGES, function(language) { return {label: language.language_name, value: language.language_code} })

    
    return {
        customer_id,
        customer,
        initial_values: {language_code: get(customer, "language_code"),
                         email: get(customer, "email")}
    }
}

export default withRouter(connect(mapStateToProps)(AdminCustomerDetails))

const header_right_child = css`
display: flex;
`

const download_button_style = css`
display: flex;
justify-content: space-between;
`


const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const address_single_line_style = css`
display: flex;
`

const edit_button_content_style = css`
display: flex;
align-items: center;
`

const edit_button_style = css`
position: absolute;
right: 40px;
`

const edit_button_row_style = css`
position: relative;
`

