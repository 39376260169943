/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import Card from './layout/Card'

export const Support = ({}) => {

    return (

        <MainLayout>

          <Card variant="white">
            
            <h2>Support</h2>

            <p>
              This site is part of a larger project around personal well-being in the context of browsing and other digitial and physcial interactions
            </p>

            <p>
              If you require any assistance or further clarification of our goals and mission, please send an email got <a href="mailto:garethpriede@gmail.com">garethpriede@gmail.com</a>
            </p>


          </Card>
          
        </MainLayout>
        
    )
}

