/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/react'
import FormError from './form/FormError'
import FormWarning from './form/FormWarning'

class InputField extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            maxLength,
            meta,
            ...extraProps
        } = this.props
        
        return (
            <div css={container}>
              
              <input {...input}
                     {...extraProps}
                     placeholder={placeholder}
                     css={[extra_class]}
                     type={type}
                     maxLength={maxLength}
                     className="form-control"
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}

export default InputField

const container = css`
width: 100%;
`

const textarea_style = css`
width: 100%;
`
