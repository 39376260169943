import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import AdminDashboard from './AdminDashboard'
import AdminReleaseNotes from './AdminReleaseNotes'
import AdminCustomers from './AdminCustomers'
import AdminCustomer from './AdminCustomer'
import AdminUsers from './AdminUsers'
import AdminUser from './AdminUser'
import AdminBrands from './AdminBrands'
import AdminBrand from './AdminBrand'
import AdminProducts from './AdminProducts'
import AdminProduct from './AdminProduct'
import AdminSkus from './AdminSkus'
import AdminSku from './AdminSku'
import AdminStoreGroups from './AdminStoreGroups'
import AdminStoreGroup from './AdminStoreGroup'
import AdminSalesRegions from './AdminSalesRegions'
import AdminSalesRegion from './AdminSalesRegion'
import AdminPharmacyAgencies from './AdminPharmacyAgencies'
import AdminPharmacyAgency from './AdminPharmacyAgency'
import AdminEditableEmails from './AdminEditableEmails'
import AdminEditableEmail from './AdminEditableEmail'
import AdminEditableEmailPreview from './AdminEditableEmailPreview'
import AdminEditableEmailCustomParameters from './AdminEditableEmailCustomParameters'
import AdminEditableEmailCustomParameter from './AdminEditableEmailCustomParameter'
import GlobalSettings from './GlobalSettings'
import AdminBackgroundJobs from './AdminBackgroundJobs'

import { isLoggedIn, loggedInUser } from '../../actions/auth'

class AuthRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }
    
    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/admin/dashboard" exact={ true } component={ AdminDashboard } />
                <Route path="/admin/release_notes" exact={ true } component={ AdminReleaseNotes } />
                <Route path="/admin/customers" exact={ true } component={ AdminCustomers } />
                <Route path="/admin/customer/:customer_id/:active_tab" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customer/:customer_id/:active_tab/:item_id" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customer/:customer_id" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/users" exact={ true } component={ AdminUsers } />
                <Route path="/admin/user/:user_id" component={ AdminUser } />
                <Route path="/admin/user" exact={ true } component={ AdminUser } />

                <Route path="/admin/brands" exact={ true } component={ AdminBrands } />
                <Route path="/admin/brand/:brand_id" component={ AdminBrand } />
                <Route path="/admin/brand" exact={ true } component={ AdminBrand } />

                <Route path="/admin/products" exact={ true } component={ AdminProducts } />
                <Route path="/admin/product/:product_id" component={ AdminProduct } />
                <Route path="/admin/product" exact={ true } component={ AdminProduct } />

                <Route path="/admin/skus" exact={ true } component={ AdminSkus } />
                <Route path="/admin/sku/:sku_id" component={ AdminSku } />
                <Route path="/admin/sku" exact={ true } component={ AdminSku } />

                <Route path="/admin/store_groups" exact={ true } component={ AdminStoreGroups } />
                <Route path="/admin/store_group/:store_group_id" component={ AdminStoreGroup } />
                <Route path="/admin/store_group" exact={ true } component={ AdminStoreGroup } />

                <Route path="/admin/sales_regions" exact={ true } component={ AdminSalesRegions } />
                <Route path="/admin/sales_region/:sales_region_id" component={ AdminSalesRegion } />
                <Route path="/admin/sales_region" exact={ true } component={ AdminSalesRegion } />

                <Route path="/admin/pharmacy_agencies" exact={ true } component={ AdminPharmacyAgencies } />
                <Route path="/admin/pharmacy_agency/:pharmacy_agency_id" component={ AdminPharmacyAgency } />
                <Route path="/admin/pharmacy_agency" exact={ true } component={ AdminPharmacyAgency } />

                <Route path="/admin/editable_emails" exact={ true } component={ AdminEditableEmails } />
                <Route path="/admin/editable_email/:editable_email_id/preview" exact={ true } component={ AdminEditableEmailPreview } />
                <Route path="/admin/editable_email/:editable_email_id" exact={ true } component={ AdminEditableEmail } />
                <Route path="/admin/editable_email_custom_parameters" exact={ true } component={ AdminEditableEmailCustomParameters } />
                <Route path="/admin/editable_email_custom_parameter/" exact={ true } component={ AdminEditableEmailCustomParameter } />
                <Route path="/admin/editable_email_custom_parameter/:editable_email_custom_parameter_id" exact={ true } component={ AdminEditableEmailCustomParameter } />
                
                <Route path="/admin/global_settings/" exact={true} component={ GlobalSettings } />
                <Route path="/admin/background_jobs/" exact={true} component={ AdminBackgroundJobs } />
                <Route path="/admin" component={ AdminDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
