/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { adminProductList } from '../actions/admin_product'
import { adminBrandList } from '../actions/admin_brand'
import { CardHeader } from '../../components/layout/CardHeader'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required"),
    'brand': Yup.string().required("Required")
})

class AdminProduct extends Component {

    componentDidMount() {
        const { dispatch, product_id } = this.props
        dispatch(adminProductList.ensureObjectLoaded(product_id))
        dispatch(adminBrandList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminBrandList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, product_id } = this.props
        dispatch(adminProductList.ensureObjectLoaded(product_id))
        dispatch(adminBrandList.fetchListIfNeeded())
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, product_id } = this.props

        const on_ok = function(json) {
            dispatch(adminProductList.invalidateList())
            showSuccess("Saved", "Product saved")

            if ( ! product_id ) {
                history.push(`/admin/product/${json.id}`)
            }
        }
        if ( product_id ) {
            values.id = product_id
            return dispatch(adminProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderForm(formik_props) {
        const { brand_options } = this.props
        return (
            <div>
              <Row>
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Brand</BootstrapForm.Label>
                    <FormikDropdownField name="brand"
                                         formik_props={formik_props}
                                         options={brand_options}
                                         placeholder="Select brand" />
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Name</BootstrapForm.Label>
                    <FormikInputField name="name" placeholder="Name" />
                  </BootstrapForm.Group>

                </Col>
              </Row>
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </div>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, product, product_id } = this.props
        const that = this
        const title = `${get(product, ["name"], 'New product')}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'products',
                                            label: 'Products',
                                            url: '/admin/products'},
                                           
                                           {name: 'product',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(product, ["name"], 'New product')}
                                                </div>
                                            ),
                                            url: `/admin/product/${product_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               <Row>
                                 <Col>
                                   <Card>
                                     { that.renderForm(formik_props) }
                                   </Card>
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const product_id = get(props, ["match", "params", "product_id"], null)
    const product = adminProductList.getObject(product_id)
    return {
        product_id,
        product,
        brand_options: adminBrandList.getAsOptions(),
        is_loading: adminProductList.isLoading(),
        is_busy: adminProductList.getIsSavingObject(),
        initial_values: product
    }
}

export default connect(mapStateToProps)(AdminProduct)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
