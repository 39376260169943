/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, keys, has, get } from 'lodash'
import AdminMainLayout from './AdminMainLayout'
import { Col, Row, Container, Button } from 'react-bootstrap'
import { globalSettingsList } from '../../actions/settings'
import { Separator } from '../../components/layout/Separator'

class AdminGlobalSettings extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    }

    render() {
        const {settings} = this.props
        
        return (
            <AdminMainLayout active_key="settings"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'settings', label: 'Global Settings', url: '/admin/global_settings'}]}>
              <Container fluid>


                { map(keys(settings), function(k) {
                    let v = settings[k]
                    if ( k === 'id' ) {
                        return null
                    }
                    if ( v === true ) {
                        v = "yes"
                    } else if ( v === false ) {
                        v = "no"
                    }
                    return (
                        <div>
                          <Row>
                            <Col md="4">
                              {k}
                            </Col>
                            <Col md="4">
                              {v}
                            </Col>
                          </Row>
                          <Separator variant="h10" />
                        </div>
                    )
                }) }
                
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        settings: globalSettingsList.getGlobalSettings()
    }
}
export default withRouter(connect(mapStateToProps)(AdminGlobalSettings))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
