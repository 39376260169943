/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx } from '@emotion/react'
import { head } from 'lodash'
import { sendTokenEmail, refreshLoggedInUserCookieFromUser } from '../actions/auth'
import { userList } from '../actions/user'
import { customerList } from '../actions/customer'
import { Container, Row, Col } from 'react-bootstrap'
import MainLayout from './MainLayout'
import ProfileForm from './ProfileForm'
import BusyMask from './BusyMask'
import { CardHeader } from './layout/CardHeader'
import Card from './layout/Card'
import { Separator } from './layout/Separator'
import { handleSubmitResult } from '../actions/form'
import {showSuccess, showError} from '../actions/Error'

class ProfileEdit extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
    }
    
    onSave = (values, formik_funcs) => {
        const { dispatch, history, user } = this.props
        let on_ok = function(json) {
            refreshLoggedInUserCookieFromUser(json)
            showSuccess("Saved", "Account updated")
            history.push("/profile")
        }
        
        return dispatch(customerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onRequestToken = () => {
        const { dispatch, user } = this.props
        dispatch(sendTokenEmail(user))
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/profile/')
    }
    
    render() {
        const { user, is_loading, customer } = this.props
        return (
            <MainLayout active_key="profile"
                        enable_breadlinks={true}
                        breadcrumbs={[{name: 'home'},
                                      {name: 'account', label: 'Account', url: '/profile'},
                                      {name: 'account_information', label: 'Edit account information', url: '/profile'}]}>
              { is_loading && <BusyMask /> }
              <Container fluid>
                <CardHeader title="Edit account information" />
                <Row>
                  <Col md="9">
                    <Card variant="white">
                      <Col>
                        <ProfileForm customer={customer} onSave={this.onSave} onCancel={this.onCancel} />
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = head(userList.getVisibleObjects()) || {}
    const customer = customerList.getCustomer()
    return {
        user,
        customer,
        is_loading: userList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(ProfileEdit))
