/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimesCircle, faTimes, faPlus, faPlusCircle, faPencilAlt,
    faTrashAlt, faCaretLeft, faCaretRight, faCaretDown, faCheckCircle,
    faCheck, faClock, faSignOutAlt, faSignInAlt, faInfoCircle, faChevronLeft, faChevronRight, faChevronDown,
    faEye, faAddressBook, faArrowLeft, faArrowRight, faDownload, faHourglassHalf, faHourglassEnd, faPrint
} from '@fortawesome/free-solid-svg-icons'

export const InlineIcon = ({ icon, icon_name, onClick, variant, size_variant, disable_margins, as_block, ...props }) => {

    let extra_css = null
    if ( variant === "blue" ) {
        extra_css = primary_dark_style
    } else if ( variant === "red" ) {
        extra_css = red_style
    } else if ( variant === "green" ) {
        extra_css = green_style
    } else if ( variant === "blue" ) {
        extra_css = blue_style
    } else if ( variant === "black" ) {
        extra_css = black_style
    } else if ( variant === "gray" ) {
        extra_css = gray_style
    }
    let size_variant_css = null
    if ( size_variant === "h14" ) {
        size_variant_css = h14
    } else if ( size_variant === "h16" ) {
        size_variant_css = h16
    } else if ( size_variant === "h18" ) {
        size_variant_css = h18
    } else if ( size_variant === "h24" ) {
        size_variant_css = h24
    }
    let hover_css = null

    switch( icon_name ) {
        case "remove":
            icon = faTimesCircle
            hover_css = hover_red_style
            break
        case "cancel":
            icon = faTimesCircle
            hover_css = hover_red_style
            break
        case "save":
            icon = faCheckCircle
            hover_css = hover_green_style
            break
        case "tick":
            icon = faCheckCircle
            break
        case "delete":
            icon = faTrashAlt
            hover_css = hover_red_style
            break
        case "edit":
            icon = faPencilAlt
            hover_css = hover_blue_style
            break
        case "add":
            icon = faPlusCircle
            hover_css = hover_blue_style
            break
        case "add_no_circle":
            icon = faPlus
            break
        case "chevron-left":
            icon = faChevronLeft
            break
        case "chevron-right":
            icon = faChevronRight
            break
        case "chevron-down":
            icon = faChevronDown
            break
        case "caret-right":
            icon = faCaretRight
            break
        case "caret-left":
            icon = faCaretLeft
            break
        case "caret-down":
            icon = faCaretDown
            break
        case "arrow-right":
            icon = faArrowRight
            break
        case "arrow-left":
            icon = faArrowLeft
            break
        case "status-success":
            icon = faCheck
            extra_css = green_style
            if ( size_variant_css === null ) {
                size_variant_css = h16
            }
            break
        case "status-in-progress":
            icon = faClock
            extra_css = orange_style
            if ( size_variant_css === null ) {
                size_variant_css = h16
            }
            break
        case "status-waiting-input":
            icon = faHourglassHalf
            extra_css = orange_style
            if ( size_variant_css === null ) {
                size_variant_css = h16
            }
            break
        case "status-expired":
            icon = faHourglassEnd
            extra_css = red_style
            if ( size_variant_css === null ) {
                size_variant_css = h16
            }
        break
        case "print":
            icon = faPrint
            break
        case "status-error":
            icon = faTimes
            extra_css = red_style
            if ( size_variant_css === null ) {
                size_variant_css = h16
            }
            break
        case "outgoing":
            icon = faSignOutAlt
            break
        case "incoming":
            icon = faSignInAlt
            break
        case "info":
            icon = faInfoCircle
            hover_css = hover_blue_style
            break
        case "eye":
            icon = faEye
            hover_css = hover_blue_style
            break
        case "address-book":
            icon = faAddressBook
            hover_css = hover_blue_style
            break
        case "download":
            icon = faDownload
            hover_css = hover_blue_style
        break
    }

    let margin_css = margin_style
    if ( disable_margins ) {
        margin_css = no_margin_style
    }

    const styles = [common_style, extra_css, size_variant_css, hover_css, margin_css]
    
    if ( onClick ) {
        return (
            <div onClick={onClick} css={[block_container, clickable_style]}>
              <FontAwesomeIcon icon={icon} css={styles} />
            </div>
        )
    } else {
        if ( as_block === true ) {
            return (
                <div css={[block_container, unclickable_style]}>
                  <FontAwesomeIcon icon={icon} css={styles} />
                </div>
            )
        } else {
            return (
                <FontAwesomeIcon icon={icon} css={styles} />
            )
        }
    }
}

const block_container = css`
display: flex;
align-items: center;
`

const common_style = css`
color: #b8b8bd;
height: 16px;
padding-bottom: 2px;
`

const no_margin_style = css`
margin-right: 0px;
`

const margin_style = css`
margin-right: 4px;
`

const clickable_style = css`
/*height: 100%;*/
/*align-items: center;*/
cursor: pointer;
`

const unclickable_style = css`
`

const primary_dark_style = css`
color: ${theme.colors.primary_dark};
`

const red_style = css`
color: ${theme.colors.primary_red};
`

const blue_style = css`
color: ${theme.colors.primary_blue};
`

const green_style = css`
color: ${theme.colors.primary_green};
`

const orange_style = css`
color: ${theme.colors.primary_orange};
`

const black_style = css`
color: #000;
`

const gray_style = css`
color: ${theme.colors.very_light_grey};
`

const h16 = css`
height: 16px;
`

const h18 = css`
height: 18px;
`

const h14 = css`
height: 14px;
`

const h24 = css`
height: 24px;
`

const hover_blue_style = css`
&:hover {
color: ${theme.colors.primary_dark};
}
`

const hover_red_style = css`
&:hover {
color: ${theme.colors.primary_red};
}
`

const hover_green_style = css`
&:hover {
color: ${theme.colors.primary_green};
}
`
