/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Row, Col, Container } from 'react-bootstrap'
import { includes, map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { customerList } from '../actions/customer'
import Card from './layout/Card'
import { Modal } from 'react-bootstrap'
import { InlineIcon } from './layout/InlineIcon'
import { WhiteButton } from './layout/WhiteButton'
import { Separator } from './layout/Separator'
import { BlueButton } from './layout/BlueButton'

class ActionNotifications extends Component {

    constructor(props) {
        super(props)
        this.state = {alert_message: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerList.ensureCustomerLoaded())
    }
    
    onCloseAlertMessage = () => {
        this.setState({alert_message: null})
    }

    renderAlertMessage(alert_message) {

        return (
            <Modal show={true}
                   onHide={this.onCloseAlertMessage} >

              <Modal.Header closeButton>
                <Modal.Title>
                  {alert_message.title}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  {alert_message.text}
                </div>
                <Separator variant="h10" />
                <div>
                  <BlueButton onClick={this.onCloseAlertMessage}>
                    Close
                  </BlueButton>
                </div>
              </Modal.Body>
              
            </Modal>
        )
    }
    
    render() {
        const { customer, disable_action_notifications } = this.props
        const { alert_message } = this.state
        
        return (
            <div>
              { alert_message && this.renderAlertMessage(alert_message) }
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { disable_action_notifications }  = props
    const customer = customerList.getCustomer()
    return {
        customer,
        disable_action_notifications
    }
}

export default withRouter(connect(mapStateToProps)(ActionNotifications))

const container_style = css`
background-color: #fff3cd;
border: 1px solid #ffeeba;
padding: 20px;
color: #856404;
margin-left: 15px;
`

const header = css`
font-size: 24px;
font-weight: 500;
`

