/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

import { isProd } from '../../actions/ui'

export const Logo = ({style}) => {

    const is_prod = isProd()
    return (
        <span>BROWSERMOOD</span>
    )

}

const default_style = css`
height: 26px;
font-size: 26px;
`
