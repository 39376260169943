/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminSkuList } from '../actions/admin_sku'
import { Container, Button, Col, Row } from 'react-bootstrap'

class AdminSkus extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminSkuList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminSkuList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/sku/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/sku/')
    }

    filterSku = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminSkuList.updateListFilter(filter_values))
    }

    getSkuCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { reseller_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>

                <FormikInputField name="any_field"
                                  placeholder="Search"
                                  show_search_icon={true} />
              </div>
            </Col>
        )
    }

    render() {
        const {
            sku_headers,
            skus,
            is_loading,
            sku_filter,
        } = this.props
        
        return (
            <AdminMainLayout active_key="skus"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'skus', label: 'Skus', url: '/admin/skus'}]}>
              <AdminTableHeader title="Skus">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter renderFilter={this.renderFilter}
                                    updateOnChange={ this.filterSku }
                  />
                </div>
                <div css={ header }>
                  <WhiteButton onClick={ this.onAdd }>
                    Add Sku
                  </WhiteButton>
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no skus."
                             headers={ sku_headers }
                             items={ skus }
                             item_list={adminSkuList}
                             getCellValue={ this.getSkuCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const skus = adminSkuList.getVisibleObjects() || []
    return {
        skus,
        sku_filter: adminSkuList.getFilter(),
        sku_headers: {
            short_code: { name: "Short code" },
            pastel_code: { name: "Pastel code" },
            name: { name: "Name" },
            product_name: { name: "Product" },
            brand_name: { name: "Brand" },
            action: { name: '' },
        },
        is_loading: adminSkuList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminSkus))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const reseller_filter_style = css`
width: 400px;
`

const filter_field_row_style = css`
display: flex;
`
