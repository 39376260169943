/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/react'

class Loading extends Component {

    render() {
        const { title } = this.props
        return (
            <div css={container}>{title || "Loading"}</div>
        )
    }
}

export default Loading

const container = css`
background-color: #fff;
color: #000;
min-height: 50px;
`

