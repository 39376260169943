/** @jsxRuntime classic */
/** @jsx jsx */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import { jsx, css } from '@emotion/react'
import { padStart } from 'lodash'

class CurrencyValue extends Component {

    render() {
        const { value, align_right, align_center, prefix, enable_inline_spacing, use_span, variant, className, showCents } = this.props

        var formatter = new Intl.NumberFormat('en-NL', {
            style: 'decimal',
            minimumFractionDigits: showCents !== false ? 2 : 0,
            maximumFractionDigits: showCents !== false ? 2 : 0,
            // style: 'currency',
            // currency: 'ZAR',
            // currencyDisplay: 'symbol',
        })

        const sign = value < 0 ? "-" : ""
        if ( ! variant || variant === "flat" ) {
            const formatted_currency = formatter.format(Math.abs(value) || 0)

            const content = [ enable_inline_spacing === true && <span key="cv1">&nbsp;</span>,
                              <span key="cv2">{ prefix || ""}{sign}€{formatted_currency}</span>,
                              enable_inline_spacing === true && <span key="cv3">&nbsp;</span>
                            ]
            if ( use_span === true ) {
                return (
                    <span className={className}>
                      {content}
                    </span>
                )
            } else {
                return (
                    <div css={[align_right === true ? align_right_style : null]}>
                      {content}
                    </div>
                )
            }
        } else if ( variant === "multiheight" ) {

            const units = Math.floor(Math.abs(value))
            const cents = padStart(`${Math.abs(value) - units}`, 2, "0")

            return (
                <div css={[multiheight_currency_value_style,
                           align_right === true ? align_right_style : null,
                           align_center === true ? align_center_style : null]}>
                  { enable_inline_spacing === true && <span>&nbsp;</span> }
                  <div css={multiheight_cents_style}>
                    {prefix || ""}{sign}€
                  </div>
                  <div css={multiheight_units_style}>
                    {units}
                  </div>
                  <div css={multiheight_cents_style}>
                    {cents}
                  </div>
                  { enable_inline_spacing === true && <span>&nbsp;</span> }
                </div>
            )
        }
    }
}

function mapStateToProps(state, props) {
    const { value, prefix, align_right } = props
    return {
        value: value,
        align_right,
        prefix
    }
}

export default connect(mapStateToProps)(CurrencyValue)

const multiheight_currency_value_style = css`
display: flex;
line-height: 64px;
`

const align_right_style = css`
text-align: right;
`

const align_center_style = css`
justify-content: center;
`

const multiheight_cents_style = css`
font-size: 20px;
line-height: 40px;
`

const multiheight_units_style = css`
font-size: 55px;
`

