/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { useDispatch } from 'react-redux'
import { Separator } from './layout/Separator'
import { withRouter } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { FormikInputField } from './form/InputField'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import InputField from './InputField'
import { Button } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import { login } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'username': Yup.string().required(),
    'password': Yup.string().required(),
})

const LoginForm = () => {

    const dispatch = useDispatch()

    const onLogin = (values) => {
        return dispatch(login(values.username, values.password))
    }
    
    const renderForm = ({formik_props, classes}) => {
        return (
            <>
              <FormikInputField name="username"
                                type="username"
                                placeholder="Username"
                                autoFocus
                                autoComplete="username"
                                formik_props={formik_props}
              />
              
              <Separator variant="h10" />
              
              <FormikInputField name="password"
                                type="password"
                                placeholder="Password"
                                formik_props={formik_props}
              />
              <Separator variant="h10" />
              <Button type="submit"
                      variant="primary"
                      size="large"
                      fullWidth
              >
                Login
              </Button>

              <>
                <Separator variant="w20" />

                <Button onClick={() => window.location="/signup"}
                        variant="secondary"
                        size="large"
                        fullWidth
                >
                  Create Account
                </Button>
                
              </>
              
              <Separator variant="h10" />
            </>
        )
    }
    
    return (

        <Formik
          initialValues={{}}
          onSubmit={onLogin}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {formik_props => {
              const { values } = formik_props
              return (
                  <Form>
                    { renderForm({formik_props}) }
                  </Form>
              )}
          }
        </Formik>
    )

}

export default LoginForm
