/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { jsx, css } from '@emotion/react'
import { Separator } from './layout/Separator'
import { Trans } from 'react-i18next'
import Card from './layout/Card'
import { Button } from 'react-bootstrap'
import MainLayout from './MainLayout'
import { FormikInputField } from './form/InputField'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Error } from './layout/Error'
import { moodMomentList } from '../actions/mood_moment'
import moment from 'moment'
import { showSuccess, showError} from '../actions/Error'
import { handleSubmitResult } from '../actions/form'
import { Footer } from './layout/Footer'
import { login } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'score': Yup.number().required(),
})

export const CaptureMoodMoment = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    
    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            dispatch(moodMomentList.invalidateList())
            showSuccess("Saved", "Mood Moment Created")
            history.push("/")
        }

        values.captured_at = moment().format()
        
        dispatch(moodMomentList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const renderForm = (formik_props) => {
        return (
            <>
              <FormikInputField name="mean_score"
                                placeholder="Your average mood since last time (0 to 1)"
                                autoFocus
                                formik_props={formik_props}
              />
              <FormikInputField name="min_score"
                                placeholder="Your lowest mood since Score (0 to 1)"
                                autoFocus
                                formik_props={formik_props}
              />
              <FormikInputField name="max_score"
                                placeholder="Your best mood since last time Score (0 to 1)"
                                autoFocus
                                formik_props={formik_props}
              />
              
              <Separator variant="h10" />
              
              <Button type="submit"
                      variant="primary"
                      size="large"
                      fullWidth
              >
                Save
              </Button>

              <Separator variant="h10" />
            </>
        )
    }

    return (
        <MainLayout>

          <Row>
            <Col md={6}>
              
              <Card variant="white">

                <h2>Create a new mood moment</h2>

                <Formik
                  initialValues={{}}
                  onSubmit={onSave}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            { renderForm({formik_props}) }
                          </Form>
                      )}
                  }
                </Formik>

              </Card>

            </Col>
          </Row>
          

        </MainLayout>
    )
}

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
`

const header = css`
text-align: center;
padding-top: 50px;
font-size: 24pt;
width: 100%;
`

const label = css`
color: #484a49;
font-weight: 600;
`

const image = css`
max-height: 100px;
`
