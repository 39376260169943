import { ItemList } from '../orm'

class ReadingMomentList extends ItemList {
    getEntityKey() {
        return "reading_moment"
    }
    
}

export const readingMomentList = new ReadingMomentList("reading_moment__default")
