/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { default_theme as theme } from '../../emotion/theme'

class AdminTableFooter extends Component {

    render() {
        const { children } = this.props
        return (
            <Container fluid>
              <Row>
                <Col>
                  {children}
                </Col>
              </Row>
            </Container>
        )
    }
}

export default AdminTableFooter
