/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { includes } from 'lodash'
import { isProd } from '../actions/ui'
import WrappingBusyMask from './WrappingBusyMask'
import Login from './Login'
import { Signup }from './Signup'
import { auto_login } from '../actions/auth'
import AdminRouter from '../admin/components/AdminRouter'
import { Home } from './Home'
import Profile from './Profile'
import ProfileEdit from './ProfileEdit'
import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isLoggedIn, loggedInUser } from '../actions/auth'
import NonProdHeading from './NonProdHeading'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Support } from './Support'
import { CaptureMoodMoment } from './CaptureMoodMoment'
import { CaptureReadingMoment } from './CaptureReadingMoment'
import { Integrations } from './Integrations'

class AuthRouter extends Component {

    renderCommonRoutes() {
        return (
            <Switch>
              <Route path="/privacy_policy" exact={true} component={PrivacyPolicy} />
              <Route path="/support" exact={true} component={Support} />
            </Switch>
        )
    }

    renderLoggedInRoutes() {
        return (
            <Switch>
              <Route path="/admin" exact={ false } component={ AdminRouter } />
              <Route path="/profile/edit" exact={ true } component={ ProfileEdit } />
              <Route path="/profile" exact={ true } component={ Profile } />
              <Route path="/home" component={ Home } />
              <Route path="/mood_moment" component={ CaptureMoodMoment } />
              <Route path="/reading_moment" component={ CaptureReadingMoment } />
              <Route path="/integrations" component={ Integrations } />
              <Route path="/" exact={true} component={ Home } />
            </Switch>
        )
    }

    renderNotLoggedInRoutes() {
        return (
            <Switch>
              <Route path="/signup" exact={ true } component={ Signup } />
              <Route path="/" render={() => <Login />} />
            </Switch>
        )
    }
    
    render() {
        const { is_logged_in, is_prod, user } = this.props

        return (
            <div>
              {this.renderCommonRoutes()}
              {is_logged_in && this.renderLoggedInRoutes()}
              {!is_logged_in && this.renderNotLoggedInRoutes()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let is_superuser = false
    const user = loggedInUser()
    if (is_logged_in) {
        is_superuser = user && user.is_superuser
    }
    return {
        user,
        is_logged_in,
        is_superuser,
        is_prod: isProd()
    }  
}
  
export default withRouter(connect(mapStateToProps)(AuthRouter))
