import { ItemList } from '../orm'

class SignupList extends ItemList {
    getEntityKey() {
        return "signup"
    }

    createAccount({username, password, invite_key}) {
        return this.saveNewObject({username, password, invite_key})
    }
    
}

export const signupList = new SignupList("singup__default")
