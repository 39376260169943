/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'

export const Footer = ({}) => {

    return null
}
