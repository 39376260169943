/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Separator } from './layout/Separator'
import { Trans } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { FormikInputField } from './form/InputField'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Error } from './layout/Error'
import { signupList } from '../actions/signup'
import { showSuccess, showError} from '../actions/Error'
import { handleSubmitResult } from '../actions/form'
import { Footer } from './layout/Footer'
import { login } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'username': Yup.string().required(),
    'password': Yup.string().required(),
    'invite_key': Yup.string().required(),
})

export const Signup = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    
    const onCreateAccount = (values, formik_funcs) => {

        const on_ok = function(json) {
            dispatch(signupList.invalidateList())
            showSuccess("Created", "Account created")
            dispatch(login(values.username, values.password))
            history.push("/")
        }
        dispatch(signupList.createAccount(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const renderForm = (formik_props) => {
        return (
            <>
              <FormikInputField name="username"
                                type="username"
                                placeholder="Username"
                                autoFocus
                                autoComplete="username"
                                formik_props={formik_props}
              />
              
              <Separator variant="h10" />
              
              <FormikInputField name="password"
                                type="password"
                                placeholder="Password"
                                formik_props={formik_props}
              />
              <Separator variant="h10" />

              <FormikInputField name="invite_key"
                                type="invite_key"
                                placeholder="Invite key"
                                formik_props={formik_props}
              />
              <Separator variant="h10" />
              
              <Button type="submit"
                      variant="primary"
                      size="large"
                      fullWidth
              >
                Create account
              </Button>

              <Separator variant="h10" />
            </>
        )
    }

    return (
        <div css={ main }>
          <div css={ container }>
            <div css={ header }>
              <p css={ label }><Trans>Create a new account</Trans></p>
            </div>

            <Formik
              initialValues={{}}
              onSubmit={onCreateAccount}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        { renderForm({formik_props}) }
                      </Form>
                  )}
              }
            </Formik>
            
          </div>
          <Footer />
        </div>
    )
}

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
padding-bottom: 20px;
`

const header = css`
text-align: center;
padding-top: 50px;
font-size: 24pt;
width: 100%;
`

const label = css`
color: #484a49;
font-weight: 600;
`

const image = css`
max-height: 100px;
`
