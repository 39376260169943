/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has } from 'lodash'
import { Button } from 'react-bootstrap'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import AdminTableFilter from './AdminTableFilter'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import Modal from '../../components/Modal'
import Container from 'react-bootstrap/Container'
import { adminUserList } from '../actions/admin_user'

class AdminUsers extends Component {

    componentDidMount() {
        this.refreshUsers()
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(adminUserList.fetchListIfNeeded())
    }

    onEditUser = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/user/'+item.id,
        })
    }

    refreshUsers = () => {
        const { dispatch } = this.props
        dispatch(adminUserList.fetchListIfNeeded())
    }

    onUpdateFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminUserList.updateListFilter(filter_values))
    }

    onAddUser = () => {
        const { history } = this.props
        history.push('/admin/user')
    }

    sortOnHeader = (key) => {
        const { list_filter, dispatch } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (list_filter.order_by === key) {
                sort_by = '-' + key
            }
            dispatch(adminUserList.updateListFilter({order_by:sort_by}))
        }
    }

    getUserCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return (
                <Button onClick={(evt) => this.onEditUser(evt, item)}>
                  Edit
                </Button>
                )
                
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    render() {
        const { is_loading, upload_relative_url, user_headers, users, list_filter } = this.props
        
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'users', label: 'Users', url: '/admin/users'}]}>
              <AdminTableHeader title="Users">
                <AdminTableFilter
                    updateOnChange={ this.onUpdateFilter }
                    form="ADMIN_USER_FILTER_FORM"
                />
                <Button variant="outline-primary" onClick={ this.onAddUser }>
                  Add User
                </Button>
              </AdminTableHeader>
              <CommonTable is_loading={ is_loading }
                           empty_message="There are no users."
                           headers={ user_headers }
                           items={ users }
                           item_list={adminUserList}
                           getCellValue={ this.getUserCellValue }
                           sortOnHeader={ this.sortOnHeader }
              />
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const users = adminUserList.getVisibleObjects()
    return {
        list_filter: adminUserList.getFilter(),
        users,
        user_headers: {
            last_name: { name: 'Last name', column_size: 2},
            first_name: { name: 'First name', column_size: 2},
            phone_number: { name: 'Phone Number', column_size: 2},
            email: { name: 'Email Address', column_size: 2},
            created_at: { name: 'Date Created', column_size: 2},
            action: { name: 'Actions', column_size: 1 }
        },
        is_loading: adminUserList.isLoading(),
        upload_relative_url: 'datafileinfo/'
    }
}
export default withRouter(connect(mapStateToProps)(AdminUsers))

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
text-align: center;
`
