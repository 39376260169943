/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import ConfirmationModal from '../../components/ConfirmationModal'
import AdminMenuTop from './AdminMenuTop'
import Breadcrumbs from '../../components/Breadcrumbs'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GlobalStyles from '../../components/GlobalStyles'
import ActionNotifications from '../../components/ActionNotifications'
import { default_theme as theme } from '../../emotion/theme'
import { Separator } from '../../components/layout/Separator'

class AdminMainLayout extends Component {
    
    render() {
        const { title, children, breadcrumbs, active_key } = this.props
        return (
            <div css={main}>
              <GlobalStyles />
              <ActionNotifications />
              <AdminMenuTop active_key={active_key} />
              <ConfirmationModal />
              <Breadcrumbs crumbs={breadcrumbs}/>
              { title && 
                <div css={ header }>
                  <h1 css={ css`margin-top:5px;` }>{ title }</h1>
                </div>
              }
              <div css={chidren_container}>
                { children }
              </div>
              <Separator variant="h100" />
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { title, layout, children } = props
    return {
        title,
        children,
    }
}
export default connect(mapStateToProps)(AdminMainLayout)

const chidren_container = css`
padding-left: 15px;
padding-right: 15px;
`

const main = css`
background-color: ${theme.colors.page_background};
font-size: 16px;
font-family: Helvetica Neue, Roboto;
`

const header = css`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
align-items: center;
color: ${theme.colors.secondary};
margin-left:30px;
`

