/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'

import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import {showSuccess, showError} from '../../actions/Error'
import { handleSubmitResult } from '../../actions/form'

class AdminUser extends Component {

    componentDidMount() {
        const { dispatch, user_id } = this.props
        dispatch(adminUserList.ensureObjectsLoaded([user_id])) 
    }
    
    onSave = (values, formik_funcs) => {
        const { dispatch, history } = this.props

        if (!values.id) {
            values.isNewInstance = true
        }
        values.notification_method = values.notification_method ? 'push_notification' : 'text_message'
        const on_ok = function() {
            showSuccess("Saved", "Subscription saved")
            dispatch(adminUserList.invalidateList())
            history.push('/admin/users')
        }
        return dispatch(adminUserList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    onCancelUser = () => {
        const { dispatch, history } = this.props
        history.push('/admin/users')
    }
    
    render() {
        const { user_id, is_busy, user } = this.props
        let title = 'Add User'
        if (user_id) {
            title = 'Edit User'
        }
        return (
            <AdminMainLayout title={ title } layout="single"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'users', label: 'Users', url: '/admin/users'},
                                           {name: 'user', label: user && user.display_name, url: `/admin/user/${user_id}`}]}>
              { is_busy && <BusyMask /> }
              <AdminUserForm
                  onSave={ this.onSave }
                  onCancelUser={ this.onCancelUser }
                  user_id={ user_id }
              />
            </AdminMainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user_id = get(props, ["match", "params", "user_id"], null)
    const user = adminUserList.getObject(user_id) || {}
    return {
        user_id,
        user,
        is_busy: adminUserList.isLoading() || adminUserList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(AdminUser)

