/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { Trans, Translation } from 'react-i18next'
import { adminCustomerList } from '../actions/admin_customer'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikTextarea } from '../../components/form/TextareaField'
import AdminCustomerDetails from './AdminCustomerDetails'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { Separator } from '../../components/layout/Separator'
import { default_theme as theme } from '../../emotion/theme'

class AdminCustomer extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }

    componentDidUpdate() {
        const { dispatch, customer, customer_id, admin_fax_from_email_address_list_filter } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
    }

    setTab = (tab_name) => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/${tab_name}`)
    }

    onViewCustomerProfile = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer_profile/${customer_id}`)
    }

    renderTabContainer() {
        const { customer_id, active_tab, item_id } = this.props
        const that = this
        return (
            <Col>
              <Tabs defaultActiveKey={active_tab} id="tabs" onSelect={that.setTab} css={customer_submenu}>
                <Tab eventKey="client_details" title="Client details">
                  <Separator variant="h15" />
                  { active_tab === "client_details" && <AdminCustomerDetails customer_id={customer_id} /> }
                </Tab>
              </Tabs>
            </Col>
        )
    }

    renderCustomerInfo() {
        const { customer } = this.props
        return (
            <div css={customer_info_style}>
              <h1>{get(customer, ["user", "display_name"])}</h1>
              <div css={customer_info_date_style}>
                Since <Timestamp value={get(customer, 'created_at')} format="date" />
              </div>
            </div>
        )
    }

    render() {
        const { customer_id, is_busy, is_loading, customer } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                           {name: 'customer', label: `${get(customer, ["user", "display_name"], '')} ${get(customer, ["short_id"])}`, url: `/admin/customer/${customer_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                { !is_loading &&
                  <Container fluid>
                    { this.renderCustomerInfo() }
                    <Separator variant="h30" />
                    <Row>
                      { this.renderTabContainer() }
                    </Row>
                  </Container>
                }
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    let active_tab = get(props, ["match", "params", "active_tab"], 'client_details')
    let secondary_tab = null
    const item_id = get(props, ["match", "params", "item_id"], null)
    const customer = customer_id && adminCustomerList.getObject(customer_id)

    if ( active_tab === 'subscription' ) {
        active_tab = 'subscriptions'
        secondary_tab = 'subscription'
    }
    
    return {
        customer_id,
        customer,
        is_loading: adminCustomerList.isLoading() || !customer || !customer.id,
        is_busy: adminCustomerList.getIsSavingObject(),
        active_tab,
        secondary_tab,
        item_id
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomer))

const customer_title_style = css`
display: flex;
`

const customer_title_since_style = css`
font-size: 16px;
display: flex;
align-items: flex-end;
padding-bottom: 3px;
font-weight: normal;
`

const customer_info_style = css`
display: flex;
align-items: baseline;
`

const customer_info_date_style = css`
display: flex;
align-items: flex-end;
padding-bottom: 5px;
margin-left: 10px;
`
const customer_submenu = css`
a {
  margin-bottom: 7px !important;
  margin-right: 4px;
  border: none !important;
  border-radius: 4px; 
}
a:hover {
  background-color: ${theme.colors.very_light_grey};
  color: #0788ca;
}
a.active {
  background-color: #e5e5ea !important;
  color: #0788ca !important;
}
`
