/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import {showSuccess, showError} from '../actions/Error'
import Card from './layout/Card'
import { Container, Row, Col } from 'react-bootstrap'
import { userList } from '../actions/user'
import Button from 'react-bootstrap/Button'

const LEFT_WIDTH = 2

export const Integrations = ({}) => {

    const dispatch = useDispatch()
    const user = useSelector(() => userList.getUser())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(userList.ensureUserLoaded())
        }
        fetchStaticObjects()
    }, [])

    const onResetApiToken = () => {

        const done = (json) => {

            if ( json.errors ) {
                showError(JSON.stringify(json.errors))
            } else {
                dispatch(userList.invalidateUser())
                showSuccess("Token reset.")
            }
        }

        dispatch(userList.resetApiToken()).then(done)
    }
    
    return (

        <MainLayout>

          <Card variant="white">
            
            <h2>Chrome Extension</h2>

            <p>
              To integrate with chrome, install the extension, and in the configuration box enter the following information:
            </p>

            <Row>
              <Col md={LEFT_WIDTH}>
                Key
              </Col>
              <Col>
                {user.api_token}
              </Col>
              <Col>
                <Button onClick={onResetApiToken}>
                  Reset token
                </Button>
              </Col>
            </Row>

          </Card>
          
        </MainLayout>
        
    )
}

