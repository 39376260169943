import { ItemList } from '../../orm'
import { union, get, map } from 'lodash'

class AdminProductList extends ItemList {
    getEntityKey() {
        return "admin/product"
    }

    getAsOptions(args) {
        const { empty_option_label } = args || {}
        let items = get(args, "items", this.getVisibleObjects())
        items = map(items, function(item) { return {label: `${item.brand_name} - ${item.name}`, value: item.id}})
        if ( empty_option_label ) {
            items = union([{label: empty_option_label, value: null}], items)
        }
        return items
    }

    
}

export const adminProductList = new AdminProductList("admin_product__default")
