/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css, Global } from '@emotion/react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing, faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { clearAuthentication, isLoggedIn } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import dashboard_light from '../images/dashboard_light.png'
import content_light from '../images/content_light.png'
import profile_light from '../images/profile_light.png'
import transactions_light from '../images/transactions_light.png'
import subscriptions_light from '../images/subscriptions_light.png'
import { Logo } from './layout/Logo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Trans } from 'react-i18next'
import profile_pic from '../images/profile_pic.svg'
import { userList } from '../actions/user'

class MenuTop extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.ensureUserLoaded())
    }

    onLogout = () => {
        const { dispatch, history } = this.props
        dispatch(clearAuthentication())
        history.push("/")
    }

    render() {
        const { user, is_logged_in } = this.props
        const active_key = this.props.active_key || "home"

        return (
            <Container fluid css={container} className="menutop_container">
              <Global styles={global_styles} />
              <Row className="main_row">
                <Col>
                  <Navbar expand="lg">
                    <div css={brand}>
                      <Logo />
                    </div>
                    <Navbar.Toggle />
                    <Navbar.Collapse css={navbar_collapse}>
                      <Nav className="mr-auto left_menu custom_menu" activeKey={active_key}>

                        <Nav.Item>
                          <Link to="/home" key="home" className={(active_key==="home" && "active") || "inactive"} >
                            <Trans>Graphs</Trans>
                          </Link>
                        </Nav.Item>

                        <NavDropdown title="Capture">
                          <NavDropdown.Item>
                            <Link to="/mood_moment" key="capture" className={(active_key==="mood_moment" && "active") || "inactive"} >
                              <Trans>Mood Moment</Trans>
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link to="/reading_moment" key="reading_moment" className={(active_key==="reading_moment" && "active") || "inactive"} >
                              <Trans>Reading Moment</Trans>
                            </Link>
                          </NavDropdown.Item>
                        </NavDropdown>
                        
                        <Nav.Item>
                          <Link to="/integrations" key="integrations" className={(active_key==="integrations" && "active") || "inactive"} >
                            <Trans>Integrations</Trans>
                          </Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Link to="/privacy_policy" key="privacy_policy" className={(active_key==="privacy_policy" && "active") || "inactive"} >
                            <Trans>Privacy Policy</Trans>
                          </Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Link to="/support" key="support" className={(active_key==="support" && "active") || "inactive"} >
                            <Trans>Support</Trans>
                          </Link>
                        </Nav.Item>
                        
                      </Nav>

                      <Nav className="ml-auto right_menu custom_menu" activeKey={active_key}>
                        { ! is_logged_in && (
                              <Nav.Item>
                                <Link to="/" className="inactive">
                                  <Trans>Login</Trans>
                                </Link>
                              </Nav.Item>
                        )}

                        { is_logged_in && (
                            <>
                              <NavDropdown title={get(user, "username")}>
                                <Nav.Item>
                                  <Link to="" onClick={ this.onLogout}>
                                    <Trans>Logout</Trans>
                                  </Link>
                                </Nav.Item>
                              </NavDropdown>
                            </>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  
                </Col>
              </Row>
            </Container>
        )
    }

}

function mapStateToProps(state, props) {
    const user = userList.getUser()
    return {
        user,
        is_logged_in: isLoggedIn()
    }
}
export default withRouter(connect(mapStateToProps)(MenuTop))

const global_styles = css`
.menutop_container .main_row {
    height: 46px;
    padding-top: 5px;
}

.menutop_container .navbar {
    padding-top: 12px;
    font-weight: 500;
}

.menutop_container .navbar-brand {
    padding-top: 0px;
}

.menutop_container .navbar.navbar-light {
    min-height: 28px;
    padding: 0px;
}
.menutop_container .navbar {
    margin-top: 4px;
    height: 28px;
}


.menutop_container .navbar-brand {
    padding-top: 7px;
}

.menutop_container .navbar-light a.nav-link {
    padding-top: 0px;
}

.menutop_container .navbar-light .navbar-nav.left_menu a {
    color: rgba(0, 0, 0, 1);
    /*height: 57px;*/
    height: 25px;
    padding-bottom: 11px;
    padding-left: 4px;
    padding-right: 4px;
  }

.menutop_container .navbar-light .navbar-nav.left_menu .nav-item:hover {
    border-radius: 4px;
    background-color: #e0ecfa;
    box-shadow: 0 0 0px 6px #e0ecfa;
}
  
.menutop_container .navbar-light .navbar-nav.custom_menu a:hover {
    text-decoration: none;
}

.menutop_container .navbar-light .navbar-nav.right_menu {
    align-items: center;
}

.menutop_container .navbar-light .navbar-nav.custom_menu a {
    color: rgba(0, 0, 0, 1);
}

.menutop_container .nav-item {
    margin-left: 23px;
    /*align-items: center;*/
    /*display: flex;*/
    line-height: 1.4;
}

.menutop_container .navbar-nav {
    height: 22px;
}

.dropdown-menu {
    padding: 10px; 
}
.dropdown-item {
    margin-bottom: 2px;
    padding: 6px 0;
    height: 30px !important;
border-radius: 4px;
}
    
.menutop_container .navbar .active {
    border-bottom: 3px solid #4178be;
    font-weight: 500;
    color: ${theme.colors.primary_blue} !important;
}

.menutop_container .navbar-brand {
    min-height: 0px;
}
`

const container = css`
background-color: #fff;
padding-left: 30px;
position: sticky;
top: 0px;
z-index: 10;
border-bottom: 1px solid #E5E5EA;
box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
`

const brand = css`
  min-height: 0px;
`

const navbar_collapse = css`
min-height: 38px;
font-weight: 500;
`

const menu_icon_style = css`
color: ${theme.colors.middle_grey};
font-size: 30px;
`
