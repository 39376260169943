/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans } from 'react-i18next'
import { Separator } from './layout/Separator'
import LoginForm from './LoginForm'
import { Error } from './layout/Error'
import { Footer } from './layout/Footer'
import { login, isLoggedIn, loggedInUser, sendForgotPasswordEmail } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'


class Login extends Component {

    constructor(props) {
        super(props)
        this.state = { tokenError: null }
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        if (!values || !values.email || !values.password) {
            window.alert("Incomplete credentials provided")
            return null
        } else {
            return dispatch(login(values.email, values.password))
        }
    }

    onRequestToken = () => {
        const { dispatch, history, loginFormValues } = this.props
        if (!loginFormValues || !loginFormValues.email) {
            this.setState({tokenError: 'No username entered'})
        } else {
            dispatch(sendForgotPasswordEmail(loginFormValues.email))
            this.setState({tokenError: null})
            history.push({
                pathname: '/token',
                state: { email: loginFormValues.email }
            })
        }
    }

    renderAutoLoginFailure() {
        const { auto_login_error_message } = this.props
        return (
            <Error>
              <div>
                <Trans>Failed to automatically login</Trans>
              </div>
              <div>
                <Trans>Either the token has expired or is invalid</Trans>
              </div>
              <div>
                <Trans>Please login normally to continue</Trans>
              </div>
            </Error>
        )
    }

    render() {
        const { tokenError } = this.state
        const { auto_login_error_message } = this.props
        return (
            <div css={ main }>
              <div css={ container }>
                <div css={ header }>
                  <p css={ label }><Trans>Browser Mood</Trans></p>
                </div>
                { auto_login_error_message && this.renderAutoLoginFailure() }
                <LoginForm
                    onSubmit={ this.handleSubmit }
                    onRequestToken ={ this.onRequestToken }
                    tokenError={ tokenError }
                />

              </div>
              <Footer />
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { auto_login_error_message } = props
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        auto_login_error_message
    }
}
export default withRouter(connect(mapStateToProps)(Login))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
padding-bottom: 20px;
`

const header = css`
text-align: center;
padding-top: 50px;
font-size: 24pt;
width: 100%;
`

const label = css`
color: #484a49;
font-weight: 600;
`

const image = css`
max-height: 100px;
`
