/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css, Global } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { clearAuthentication, loggedInUser } from '../../actions/auth'
import { default_theme as theme } from '../../emotion/theme'
import dashboard_light from '../../images/dashboard_light.png'
import content_light from '../../images/content_light.png'
import profile_light from '../../images/profile_light.png'
import transactions_light from '../../images/transactions_light.png'
import subscriptions_light from '../../images/subscriptions_light.png'
import { Logo } from '../../components/layout/Logo'
import { SeparatorWithLine } from '../../components/layout/SeparatorWithLine'
import { Row, Col, Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'

class AdminMenuTop extends Component {

    onLogout = () => {
        const { dispatch, history } = this.props
        dispatch(clearAuthentication())
        history.push("/")
    }

    renderProfileMenu() {
        return (
            <div css={profile_menu_style}>
              <div css={profile_menu_bottom_style}>
                <div css={profile_menu__item}>
                  <Link to="" onClick={ this.onLogout}>
                    <Trans>Log out</Trans>
                  </Link>
                </div>
              </div>
            </div>
        )
    }
    
    render() {
        const { email, is_logged_in } = this.props
        const active_key = this.props.active_key || "home"
        return (
            <Container fluid css={container} className="menutop_container">
              <Global styles={global_styles} />
              <Row className="main_row">
                <Col>
                  <Navbar expand="lg">
                    <div css={brand}>
                      <Logo />
                    </div>
                    <Navbar.Toggle />
                    <Navbar.Collapse css={navbar_collapse}>
                      <Nav className="mr-auto" className="left_menu" activeKey={active_key}>
                        
                        <Nav.Item>
                          <Link to="/admin/" className={(active_key==="home" && "active") || "inactive"}>
                            Home
                          </Link>
                        </Nav.Item>
                        
                      </Nav>

                      <Nav className="ml-auto right_menu custom_menu" activeKey={active_key}>
                        { ! is_logged_in && (
                            <Nav.Item>
                              <Link to="/" className="inactive">
                                <Trans>Login</Trans>
                              </Link>
                            </Nav.Item>
                        )}
                      
                        <NavDropdown css={profile_dropdown_toggle} title={`Admin`}
                                     eventKey="user">
                          { this.renderProfileMenu() }
                        </NavDropdown>
                        
                      </Nav>

                      
                    </Navbar.Collapse>
                  </Navbar>
                </Col>
              </Row>
            </Container>
        )
    }

}

function mapStateToProps(state, props) {
    const user = loggedInUser()
    return {
        is_logged_in: user && user.id,
        email: user && user.email
    }
}
export default withRouter(connect(mapStateToProps)(AdminMenuTop))

const global_styles = css`

.menutop_container .main_row {
    height: 46px;
    padding-top: 5px;
}

.menutop_container .navbar-brand {
    padding-top: 0px;
}

.menutop_container .navbar.navbar-light {
    min-height: 28px;
    padding: 0px;
    padding-top: 2px;
}
.menutop_container .navbar {
    margin-top: 4px;
    height: 28px;
}

.menutop_container .navbar-brand {
    padding-top: 7px;
}

.menutop_container .navbar-light a.nav-link {
    padding-top: 0px;
}

.menutop_container .navbar-light .navbar-nav.left_menu a {
    color: rgba(0, 0, 0, 1);
    /*height: 57px;*/
    height: 25px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.menutop_container .navbar-light .navbar-nav.left_menu .nav-item:hover {
    border-radius: 4px;
    background-color: #e0ecfa;
    box-shadow: 0 0 0px 6px #e0ecfa;
}

.menutop_container .navbar-light .navbar-nav.left_menu a:hover {
  color: ${theme.colors.primary_blue} !important;
}
.menutop_container .navbar-light .navbar-nav a:hover {
  text-decoration: none;
}
.menutop_container .navbar-light .navbar-nav.custom_menu a {
  color: rgba(0, 0, 0, 1);
}
  
.menutop_container .nav-item {
    margin-left: 23px;
    /*align-items: center;*/
    /*display: flex;*/
    text-align: center;
    line-height: 1.4;
}
.menutop_container .navbar-light .navbar-nav .nav-item a {
    padding-left: 4px;
    padding-right: 4px;
}

.menutop_container .navbar-nav {
  height: 22px;
}

.menutop_container .navbar .active {
    font-weight: 500;
    color: ${theme.colors.primary_blue} !important;
}

.menutop_container .navbar-brand {
    min-height: 0px;
}

.dropdown-menu {
    padding: 10px; 
}
.dropdown-item {
    margin-bottom: 2px;
    padding: 6px 0;
    height: 30px !important;
    border-radius: 4px;
}
`

const container = css`
background-color: #fff;
padding-left: 30px;
position: sticky;
top: 0px;
z-index: 10;
border-bottom: 1px solid #E5E5EA;
box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
`

const brand = css`
  min-height: 0px;
`

const navbar_collapse = css`
min-height: 38px;
font-weight: 500;
`



const profile_dropdown_toggle = css`

a {
padding-bottom: 0px;
}

a::after {
  display: none; /* hide the toggle carat */
}

&:hover {
font-weight: 400;
}

`

const profile_menu_style = css`
padding-top: 16px;
padding-bottom: 19px;
width: 243px;
position:absolute;
right: 115px;
top: -10px;
background: #fff;
border-radius: 4px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
border: solid 1px rgba(0, 0, 0, 0.15);
font-weight: 400;
`


const profile_menu_bottom_style = css`
padding-left: 25px;
`

const profile_menu__name_style = css`
color: ${theme.colors.menu_black};
font-size: 16px;
`

const profile_menu__info_style = css`
color: ${theme.colors.dark_grey};
font-size: 14px;
font-weight: 400;
`

const profile_menu__item = css`
color: ${theme.colors.black};
font-size: 16px;
&:hover {
font-weight: 500;
}
a {
  color: ${theme.colors.black};
}
}
`

const inner_dropdown_style = css`
font-size: 12px;
margin-left: 4px !important;
margin-top: 5px;
font-weight: normal !important;
`
