/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, has, get, replace, split } from 'lodash'
import { Modal } from 'react-bootstrap'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { Row, Col, Table } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminBackgroundJobList } from '../actions/admin_background_job'
import { Separator } from '../../components/layout/Separator'
import * as Yup from 'yup'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { globalSettingsList } from '../../actions/settings'

const validationSchema = Yup.object().shape({
    'action': Yup.string().required("Required")
})

class AdminBackgroundJobs extends Component {

    constructor(props) {
        super(props)
        this.state = {show_sync_form: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminBackgroundJobList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminBackgroundJobList.fetchListIfNeeded())
    }

    onRunJob = (job_id) => {
        const { dispatch } = this.props
        const that = this
        let on_ok = function(json) {
            showSuccess("Job", "Job triggered")
            dispatch(adminBackgroundJobList.invalidateList())
        }
        dispatch(adminBackgroundJobList.run(job_id)).then((res) => handleSubmitResult({res, on_ok}))
    }

    renderBackgroundJob = (job) => {
        return (
            <div>
              <GrayButton onClick={() => this.onRunJob(job.name)}>
                {job.name}
              </GrayButton>
            </div>
        )
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <GrayButton onClick={() => this.onRunJob(item.id)}>
                      Run now
                    </GrayButton>
                )
            default:
                return undefined
        }
    }

    render() {
        const {headers, background_jobs, is_loading} = this.props
        
        return (
            <AdminMainLayout active_key="background_jobs"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'background_jobs', label: 'Background Jobs', url: '/admin/background_jobs'}]}>
              <Container fluid>

                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no background jobs."
                    headers={ headers }
                    items={ background_jobs }
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const background_jobs = adminBackgroundJobList.getVisibleObjects() || []
    return {
        background_jobs,
        is_loading: adminBackgroundJobList.isLoading(),
        headers: {
            name: { name: "Name" },
            description: { name: "Description" },
            action: { name: "" }
        }
    }
}
export default withRouter(connect(mapStateToProps)(AdminBackgroundJobs))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
